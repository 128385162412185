<template>
  <section ref="elementRoot" class="banner-collections-block custom-section rm-pt">
    <ClientOnly>
      <template #fallback>
        <div class="swiper swiper-initialized swiper-horizontal">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div
                v-if="firstSlide"
                class="banner-collections-slider"
                :style="`--text-color: ${firstSlide.color || 'white'}`"
              >
                <div class="container">
                  <div class="banner-collections-slider__content">
                    <div
                      class="banner-collections-slider__title h1"
                      v-html="firstSlide.title"
                    />
                    <div
                      class="banner-collections-slider__message"
                      v-html="firstSlide.text"
                    />
                    <div class="banner-collections-slider__link">
                      <nuxt-link :to="firstSlide.link">
                        Подробнее
                      </nuxt-link>
                    </div>
                  </div>
                </div>
                <div
                  class="banner-collections-slider__image"
                  :style="`--opacity-bg-pc: ${(firstSlide.opacity_black_background_percent / 100)}; --opacity-bg-mobile: ${(firstSlide.opacity_black_background_percent_mobile / 100)}`"
                >
                  <nuxt-img
                    :preload="true"
                    :src="getImageId(firstSlide)"
                    :height="$viewport.isLessThan('lg') ? '315' :'820'"
                    fit="cover"
                    loading="eager"
                    class="--mobile-top"
                    :alt="firstSlide.title"
                  />
                  <nuxt-img
                    :preload="true"
                    :src="getImageId(firstSlide)"
                    :height="$viewport.isLessThan('lg') ? '315' :'820'"
                    fit="cover"
                    loading="eager"
                    :alt="firstSlide.title"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <swiper
        effect="cards"
        :modules="[EffectFade, Pagination, Virtual, Autoplay]"
        :loop="true"
        :autoplay="{delay: 5000}"
        :virtual="isVirtual"
        :pagination="{ clickable: true }"
        :observer="true"
        :observe-parents="true"

        @init="onSwiperInit"
        @slide-change="onSlideChange"
      >
        <swiper-slide
          v-for="(banner, index) in banners"
          :key="`main-slide-${banner.image_id}-${index}`"
          :class="{'swiper-slide-visible': banner.isSsr}"
        >
          <div
              class="banner-collections-slider"
              :style="`--text-color: ${banner.color || 'white'}`"
            >
              <div class="container">
                <div class="banner-collections-slider__content">
                  <div
                    class="banner-collections-slider__title h1"
                    v-html="banner.title"
                  />
                  <div
                    class="banner-collections-slider__message"
                    v-html="banner.text"
                  />
                  <div class="banner-collections-slider__link">
                    <nuxt-link :to="banner.link">
                      Подробнее
                    </nuxt-link>
                  </div>
                </div>
              </div>
              <div
                class="banner-collections-slider__image"
                :style="`--opacity-bg-pc: ${(banner.opacity_black_background_percent / 100)}; --opacity-bg-mobile: ${(banner.opacity_black_background_percent_mobile / 100)}`"
              >
                <nuxt-img
                  :preload="Boolean(index === 0)"
                  :src="getImageId(banner)"
                  :height="$viewport.isLessThan('lg') ? '315' :'1000'"
                  fit="cover"
                  :loading="Boolean(index > 0) ? 'lazy' : 'eager'"
                  class="--mobile-top"
                  :alt="banner.title"
                  :placeholder="Boolean(index > 0)"
                  onContextMenu="return false;"
                />
                <nuxt-img
                  :preload="Boolean(index === 0)"
                  :src="getImageId(banner)"
                  :height="$viewport.isLessThan('lg') ? '315' :'1000'"
                  fit="cover"
                  :loading="Boolean(index > 0) ? 'lazy' : 'eager'"
                  :alt="banner.title"
                  :placeholder="Boolean(index > 0)"
                  onContextMenu="return false;"
                />
              </div>
            </div>
        </swiper-slide>

        <div
          class="banner-collections-block__navigation --left"
          @click="prevSlide"
        >
          <img
            src="~/assets/img/svg/common/arrow.svg"
            alt="стрелка влево"
          >
        </div>
        <div
          class="banner-collections-block__navigation --right"
          @click="nextSlide"
        >
          <img
            src="~/assets/img/svg/common/arrow.svg"
            alt="стрелка вправо"
          >
        </div>

        <div class="banner-collections-block__custom-mobile-pagination">
          <div
            v-for="(indexCount) in customPaginationCount"
            :key="`banner-collections-block__custom-mobile-pagination-${ indexCount }`"
            :class="{'active': Boolean(indexCount.includes(activeSlide))}"
          />
        </div>
      </swiper>
    </ClientOnly>
  </section>
</template>

<script lang="ts" setup>
import { EffectFade, Pagination, Virtual, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, type ComputedRef, ref } from 'vue';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps<IBlockDefaultProps>();
const { ssrContext } = useNuxtApp();
const $viewport = useViewport();

const activeSlide: Ref<number> = ref(1);
const banners = computed(() => {
  const extras: any = props?.extras;
  const _banners = [...(extras?.banners || [])];
  if (ssrContext) {
    return _banners.splice(0, 1).map((item: any) => {
      return {
        ...item,
        isSsr: true
      };
    });
  }
  return _banners;
});
const customPaginationCount = computed(() => {
  const _list: any = [];
  if (banners.value.length <= 5) {
    new Array(banners.value.length).fill(1).map((item, index) => {
      _list.push([index + 1]);
    });
    return _list;
  }

  const count: number = banners.value.length;
  const centerMin = 3;
  const centerMax = count - 2;

  return [
    [1],
    [2],
    Array.from({ length: (centerMax - centerMin) / 1 + 1 }, (value, index) => centerMin + index * 1),
    [count - 1],
    [count]
  ];
});
const onSlideChange = () => {
  activeSlide.value = (refSwiper.value?.realIndex || 0) + 1;
};

const isVirtual = computed(() => {
  return Boolean(!ssrContext && banners.value.length > 8);
});

const refSwiper: any = ref(null);

const prevSlide = () => {
  refSwiper.value?.slidePrev();
};
const nextSlide = () => {
  refSwiper.value?.slideNext();
};
const onSwiperInit = (element: any) => {
  refSwiper.value = element;
};

const firstSlide: ComputedRef<any> = computed(() => {
  return banners.value[0]
});
const getImageId = (banner: any): string => {
  let imageId = banner?.image_id;
  if ($viewport.isLessThan('lg') && banner?.mobile_image_id) {
    imageId = banner?.mobile_image_id;
  }
  return String(imageId)
};

const elementRoot = ref<HTMLDivElement | null>(null);
const { stop } = useIntersectionObserver(elementRoot, async ([{ isIntersecting }]) => {
    if (refSwiper.value) {
      if (isIntersecting) {
        refSwiper.value!.enable();
      } else {
        refSwiper.value!.disable();
      }
    }
  });

const isUsedZoom: Ref<boolean> = ref(false);
const checkUsedZoom = () => {
  if (!refSwiper.value) {
    return;
  }

  const currentScale = window.visualViewport?.scale || 1;
  if (!isUsedZoom.value && currentScale <= 1.1) {
    return;
  }

  if (currentScale <= 1.1) {
    isUsedZoom.value = false;
    refSwiper.value!.enable();
    return;
  }

  isUsedZoom.value = true;
  refSwiper.value!.disable();
};
onMounted(() => {
  window.addEventListener("touchmove", checkUsedZoom)
});
onBeforeUnmount(() => {
  stop();
  window.removeEventListener("touchmove", checkUsedZoom)
});
</script>

<style lang="scss">
.banner-collections-block {
	//margin-top: -40px;
	margin-top: -2.2%;
	position: relative;
	overflow: hidden;

	.swiper {
		border-radius: 0 0 40px 40px;
	}

	.swiper-slide {
		display: flex;

		//&.swiper-slide-visible {
		//	pointer-events: initial !important;
		//}
    //
		//&:not(.swiper-slide-visible) {
		//	opacity: 0 !important;
		//}
	}

	.swiper-pagination {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: auto;
		left: initial;
		bottom: 64px;
		right: calc((100vw - var(--width-container)) / 2);

		& > * {
			height: 4px;
			width: 32px;
			border-radius: 100px;
			opacity: 0.5;
			background: #F5F5F5;
			transition: all 0.3s;

			&.swiper-pagination-bullet-active {
				width: 64px;
				opacity: 1;
				background-color: white;
			}
		}
	}

	.h1 {
		color: var(--text-color, white);
	}
}

.banner-collections-block__navigation {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	width: 36px;
	height: 36px;
	z-index: 5;
	cursor: pointer;

	img {
		width: 28px;
		height: 28px;
		object-fit: contain;
		filter: invert(1);
		transition: all 0.2s;
	}

	&.--left {
		left: calc((100% - var(--width-container)) / 2 - 36px);
	}

	&.--right {
		right: calc((100% - var(--width-container)) / 2 - 36px);

		img {
			transform: rotate(180deg);
		}
	}
}

.banner-collections-slider {
	display: flex;
	width: 100%;
	position: relative;
	z-index: 0;

	&::after {
		content: "";
		float: left;
		padding-top: 33.3335%;
	}
}

.banner-collections-slider__content {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 680px;
	padding-top: 128px;
	margin-left: 108px;
	box-sizing: border-box;
}

.banner-collections-slider__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
    pointer-events: none;
    user-select: none;
	}
  .--mobile-top {
    display: none;
  }

	&:after {
		content: "";
		position: absolute;
    z-index: 1;
    pointer-events: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: var(--opacity-bg-pc);
	}
}

.banner-collections-slider__title {
	margin-bottom: 24px;
	color: var(--text-color, white);
}

.banner-collections-slider__message {
	color: var(--text-color, white);
	font-size: 16px;
	font-weight: 600;
	line-height: 140%;
}

.banner-collections-slider__link {
	display: flex;
	align-items: flex-start;
	margin-top: 64px;

	a {
		color: var(--text-color, white);
		font-size: 16px;
		line-height: 100%;
		font-weight: 600;
		text-transform: uppercase;
		border-bottom: 1px solid;
		transition: all 0.2s;

		&:hover {
			border-color: transparent;
		}
	}
}

.banner-collections-block__custom-mobile-pagination {
	display: none;
	align-items: flex-end;
}

@media (max-width: 1699px) {
	.banner-collections-block .swiper {
		border-radius: 0 0 30px 30px;
	}
	.banner-collections-slider__content {
		padding-top: 137px;
		margin-left: 99px;
	}
	.banner-collections-slider::after {
		//min-height: 570px;
	}
	.banner-collections-block__navigation {
		&.--left {
			left: calc((100% - var(--width-container)) / 2);
		}

		&.--right {
			right: calc((100% - var(--width-container)) / 2);
		}
	}
}

@media (max-width: 1359px) {
	.banner-collections-slider__content {
		margin-left: 64px;
	}
}

@media (max-width: 1199px) {
  .banner-collections-block {
    margin-top: -4%;
  }
	.banner-collections-slider__content {
		margin-left: 48px;
    padding-top: 80px;
	}
  .banner-collections-slider__title {
    margin-top: 16px;
  }
}

@media (max-width: 1023px) {
	.banner-collections-slider {
    margin-top: -40px;
		height: calc(100vh - 165px);
		@supports (height: calc(100svh - 165px)) {
			height: calc(100svh - 165px);
		}
	}
  .banner-collections-slider__image {
    padding-top: 40px;

    img {
      position: relative;
      z-index: 1;
    }
    .--mobile-top {
      display: block;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      height: calc(100% - 40px);
      transform: scaleY(-1);
      top: initial;
      bottom: calc(100% - 40px);
    }
    &:after {
      opacity: var(--opacity-bg-mobile);
    }
  }
	.banner-collections-slider__content {
		margin: 0;
		padding: 0;
		padding-top: 88px;
	}
	.banner-collections-block__navigation {
		top: initial;
		bottom: 32px;
		width: 32px;
		height: 32px;
		background-color: white;
		border-radius: 6px;
		padding: 8px;
		box-sizing: border-box;

		&.--left {
			left: 20px;
		}

		&.--right {
			left: 64px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
			filter: initial;
		}
	}
	.banner-collections-block .swiper-pagination {
		display: none !important;

	}
	.banner-collections-block__custom-mobile-pagination {
		display: flex;
		position: absolute;
		bottom: 32px;
		right: 20px;
		z-index: 5;
		pointer-events: none;
		gap: 8px;

		& > * {
			width: 24px;
			height: 3px;
			background-color: #F5F5F5;
			opacity: 0.5;
			border-radius: 100px;
			transition: all 0.2s;

			&.active {
				width: 48px;
				opacity: 1;
				background-color: white;
			}
		}
	}
}

@media (max-height: 719px) and (max-width: 1023px) {
	.banner-collections-slider {
		height: calc(100vh - 120px);
		@supports (height: calc(100svh - 120px)) {
			height: calc(100svh - 120px);
		}
	}
}
</style>
